import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import 'material-icons/iconfont/material-icons.css'

// Tailwind
import '@/assets/css/main.css'

import i18n from './i18n/i18n'

Vue.use(require('vue-cookies'))
if (!Vue.$cookies.get('locale')) {
  Vue.$cookies.set('locale', 'en')
}

// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)

// ACL
import acl from './acl/acl'

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  acl,
  render: (h) => h(App)
}).$mount('#app')
