// en, ar
export default {
  en: {
    'Warning': 'Warning',
    'Slot has been updated successfully!': 'Slot has been updated successfully!',
    'You can take a photo with a mobile phones only!': 'You can take a photo with a mobile phones only!',
    'Login Attempt': 'Login Attempt',
    'Only supports upload .png, .jpg suffix files': 'Only supports upload .png, .jpg suffix files',
    'Only support uploading one file!': 'Only support uploading one file!'
  },
  ar: {
    'Warning': 'Warning',
    'Slot has been updated successfully!': 'Slot has been updated successfully!',
    'You can take a photo with a mobile phones only!': 'You can take a photo with a mobile phones only!',
    'Login Attempt': 'Login Attempt',
    'Only supports upload .png, .jpg suffix files': 'Only supports upload .png, .jpg suffix files',
    'Only support uploading one file!': 'Only support uploading one file!'
  }
}
