import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/auth/authService'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/ar'
    },
    // CUSTOMER PAGES
    {
      path: '/:lang',
      component: () => import('@/layouts/customer/CustomerMain.vue'),
      children: [
        {
          path: '/',
          redirect: '/pages/login'
        },
        {
          path: 'home',
          name: 'home',
          component: () => import('@/views/customer/Home.vue'),
          meta: {
            authRequired: true,
            rule: 'customer'
          }
        },
        {
          path: 'received-products',
          name: 'received-products',
          component: () => import('@/views/customer/ReceivedProducts.vue'),
          meta: {
            authRequired: true,
            rule: 'customer'
          }
        },
        {
          path: 'outgoing-shipments',
          name: 'outgoing-shipments',
          component: () => import('@/views/customer/OutgoingShipments.vue'),
          meta: {
            authRequired: true,
            rule: 'customer'
          }
        }
      ]
    },
    // OPERATIONS PAGE
    {
      path: '/:lang',
      component: () => import('@/layouts/operations/OperationsMain.vue'),
      children: [
        {
          path: 'operations/dashboard',
          name: 'operations-dashboard',
          component: () => import('@/views/operations/Dashboard.vue'),
          meta: {
            authRequired: true,
            rule: 'operations'
          }
        },
        {
          path: 'operations/received-products',
          name: 'operations-received-products',
          component: () => import('@/views/operations/ReceivedProducts.vue'),
          meta: {
            authRequired: true,
            rule: 'operations'
          }
        },
        {
          path: 'operations/outgoing-shipments',
          name: 'operations-outgoing-shipments',
          component: () => import('@/views/operations/OutgoingShipments.vue'),
          meta: {
            authRequired: true,
            rule: 'operations'
          }
        },
        {
          path: 'operations/storage-slots',
          name: 'operations-storage-slots',
          component: () => import('@/views/operations/Slots.vue'),
          meta: {
            authRequired: true,
            rule: 'operations'
          }
        }
      ]
    },
    // GUEST PAGES
    {
      path: '/:lang',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: 'auth-callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: 'pages/signup',
          name: 'page-signup',
          component: () => import('@/views/pages/Registration.vue'),
          meta: {
            rule: 'guest'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'guest'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    if (!(auth.isAuthenticated())) {
      next({name: 'page-login'})
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
