<template>
  <div id="app">
    <div id="loader">
      <div id="spinner"></div>
    </div>
    <div id="containerImage" class="hidden">
      <div class="relative image-zoomed">
        <span @click="closeProductImage" class="material-icons">close</span>
        <img id="productImage" alt="">
      </div>
    </div>
    <ul class="notify-container">
      <li id="notify" class="notify hidden">
        <p id="notifyTitle" class="font-semibold mb-2 leading-tight"></p>
        <p id="notifyText" class="text-sm font-medium leading-tight"></p>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  watch: {
    '$route.params.lang' (val) {
      val = this.$cookies.get('locale') ? this.$cookies.get('locale') : 'en'
      const dir = val === 'en' ? 'ltr' : 'rtl'
      localStorage.setItem('locale', val)
      this.$cookies.set('locale', val, '100y')
      document.documentElement.setAttribute('dir', dir)
    }
  },
  methods: {
    closeProductImage () {
      document.getElementById('containerImage').classList.add('hidden')
    }
  },
  async created () {
    const dir = this.$cookies.get('locale') === 'en' ? 'ltr' : 'rtl'
    document.documentElement.setAttribute('dir', dir)
    if (this.$cookies.get('locale') === 'en') {
      this.$i18n.locale = 'en'
    } else {
      this.$i18n.locale = 'ar'
    }
    
    window.addEventListener('storage', event => {  
      if (event.key === 'accessToken' && !event.newValue) {
        this.$auth.logOut()
      }
    })
    // Auth0
    // eslint-disable-next-line no-empty
    try       { this.$auth.renewTokens() } catch {}

  }
}
</script>

<style lang="scss" src="@/assets/css/styles/main.scss"></style>